import axios from "axios";
import config from "@/config";

const state = {
    managers: []
}

const getters = {

    getManagers(state) {
        return state.managers
    }

}

const mutations = {
    setAllManagers(state, data) {
        state.managers = data
    }
}

const actions = {
    fetchAllManagers(context) {

        if (!context.state.managers.length) {
            axios.get(config.api + 'users')
                .then(response => {
                    context.commit('setAllManagers', response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }

    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};