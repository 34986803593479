const state = {
    productList: [],
    printDetails: {
        name: '',
        date: '',
        sale: ''
    }
}

const getters = {
    getProductListPrint(state) {
        return state.productList
    },

    getPrintDetails(state) {
        return state.printDetails
    }
}

const mutations = {
    setProductListPrint(state, data) {
        state.productList = data
    },

    setPrintDetails(state, data) {
        state.printDetails = data
    }
}

const actions = {

}

export default {
    state,
    getters,
    mutations,
    actions,
};