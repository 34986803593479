import Vue from 'vue'
import Vuex from 'vuex'
import print from "@/store/modules/print";
import menu from "@/store/modules/menu";
import overlay from "@/store/modules/overlay";
import modals from "@/store/modules/modals";
import base from "@/store/modules/base";
import category from "@/store/modules/category";
import clients from "@/store/modules/clients";
import orders from "@/store/modules/orders";
import userConfig from "@/store/modules/userConfig";
import managers from "@/store/modules/managers";


Vue.use(Vuex)



export default new Vuex.Store({
  state: {

  },

  getters: {
    mobileMenuLinks: state => {
      return state.mobileMenuLinks;
    },
  },

  mutations: {},
  actions: {},
  modules: {
    print,
    menu,
    overlay,
    modals,
    base,
    category,
    clients,
    orders,
    userConfig,
    managers
  }
})