<template>
    <section class="zelray-panel">
        <div class="zelray-panel__container _container">
            <div class="zelray-panel__wrapper" ref="wrapper">
                <ul>
                   <li v-for="item in links" :key="item.href" >
                      <router-link :to="item.href" active-class="is-active" exact-active-class="is-active" >
                        <span :style="`background-image: url('${item.icon}')`"></span>{{ item.label }}
                      </router-link> 
                    </li>
                </ul>
                <div class="zelray-panel__currency">
                  <div class="dropdown-currency">
                    <div class="dropdown-currency__title"><span @click="changeCurrency()" >{{ getUserConfig.currency }}</span></div>
                  </div>
                </div>

                <div class="zelray-panel__user">
                   <div class="user-bar">
                    <div class="user-bar__info">
                        <div class="user-bar__avatar">
                          <span>{{ getUserConfig.name[0] }}</span>
                        </div>
                        <div class="user-bar__main">
                          <div class="user-bar__name">{{ getUserConfig.name }}</div>
                          <div class="user-bar__email">{{ getUserConfig.email }}</div>
                        </div>
                    </div>
                   </div>

                </div>

                <div class="zelray-panel__logout">
                     
                      <router-link to="/logout" active-class="is-active" exact-active-class="is-active" >
                        <span :style="`background-image: url('/img/vectors/ic_logout.svg')`"></span> 
                      </router-link>
                </div>
            </div>
        </div>

        <bottomSheet ref="selectCurrencyPopup" maxWidth="380px" >
          <div class="popup-currency">
            <div class="popup-currency__title"><h5>Выберите основную валюту</h5></div>
            <div class="popup-currency__list">
              <div v-for="item in currencyArray" :key="item.code" class="popup-currency__item" @click="selectCurrency(item.code)" :class="{'is-active': getUserConfig.currency == item.code}">
                <span>{{ item.code }}</span> {{ item.desc }}
              </div>
               
            </div>
          </div>
        </bottomSheet>

    </section>
</template>

<script>

import bottomSheet  from '@/common-components/bottom-sheet'
import { mapMutations, mapGetters } from 'vuex' 

export default {
  name: "top-bar",

  components: {
    bottomSheet
  },
   
  data () {
    return {

      currencyArray: [
        {
          code: 'USD',
          desc: 'Доллар США'
        },
        {
          code: 'BYN',
          desc: 'Беларуский рубль'
        },
      ],

      links: [
        {
          href: "/base",
          label: 'Товары',
          icon: '/img/vectors/ic_goods.svg'
        },
        {
          href: "/clients",
          label: 'Клиенты',
          icon: '/img/vectors/ic_users.svg'
        },
        {
          href: "/orders",
          label: 'Заявки за 3 дня',
          icon: '/img/vectors/ic_orders.svg'
        },
        {
          href: "/cart",
          label: 'Корзина',
          icon: '/img/vectors/ic_trash-white.svg'
        },
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getUserConfig'
    ])
  },

   mounted () {
    this.$bus.on('updateTopbar', () => {

      setTimeout(() => {
        this.scrollToElem(this.$refs.wrapper)
      }, 100)

    })
   },

   methods: {
    
    scrollToElem(container) {

            if(!container) return false

            let elem = container.querySelector('.is-active')

            var rect = elem.getBoundingClientRect();
            var rectContainer = container.getBoundingClientRect();

            let elemOffset = {
                top: rect.top + document.body.scrollTop,
                left: rect.left + document.body.scrollLeft
            }

            let containerOffset = {
                top: rectContainer.top + document.body.scrollTop,
                left: rectContainer.left + document.body.scrollLeft
            }

            let leftPX = elemOffset.left - containerOffset.left + container.scrollLeft - (container.offsetWidth / 2) + (elem.offsetWidth / 2) + 5

            container.scrollTo({
                left: leftPX,
                behavior: 'smooth'
            });
    },

    changeCurrency() {
      this.$refs.selectCurrencyPopup.open()
    },

    ...mapMutations([
      'setCurrency'
    ]),

    selectCurrency(code) {
      this.setCurrency(code)
      this.$refs.selectCurrencyPopup.close()
    }

   }
  
  
}

</script>

<style src="./style.scss" lang="scss"/>